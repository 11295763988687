<script src="http://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<template>
  <div>
    <!-- <img src="img/ketang.png" class="wximg" /> -->
    <!-- 动态数据页面 -->
    <!-- <img src="https://health-planet.oss-cn-shanghai.aliyuncs.com/report/2023-09-09/8e5fd417a6fd9a4637f829e5810bc62a74a4.jpg" class="wximg" /> -->
    <!-- 我的健康状态页面 -->
    <div style="width: 100%; padding: 0.3rem 0.3rem; background-color: rgb(213, 234, 247); color: rgb(87, 161, 240);text-align: center; font-size: 0.9rem;" >如果验证失败可扫码跳转小程序查看</div>
    <!-- <div>wxUserId: {{wxUserId}}</div>
    <div>idCard: {{idCard}}</div>
    <div>window.Android是否存在: {{ifShow}}</div>
    <div>window.Android是否存在: {{logShow}}</div>
    <div>Android: {{Android}}</div>
    <button class="my-button my-button-primary" @click="getParams">
      <slot>按钮</slot>
    </button> -->
    <img src="https://health-planet.oss-cn-shanghai.aliyuncs.com/report/2024-12-28/b7d583d9970f6a4c1c182b89936bfb167db9.png" class="wximg" />
    <!-- <div class="gotext" @click="goApp()">点击跳转</div> -->
  </div>
</template>
<script>

import {
  getList
} from '@/api/health/test'
import { userInfo } from '../../api/login'
import { request } from '@/util/http'

export default {
  name: 'kindex',
  data () {
    return {
      requestHeadUrl: 'https://dbadmin.dbzdjk.com',
      // requestHeadUrl: 'https://douyin.kequbang.com',
      // requestHeadUrl: 'http://localhost:8180',
      // isOpenVipModular: false,
      // showModal: false, // 是否展示弹窗
      // isVip: 0,
      // storeId: '',
      // userId: '',
      // isMiniProgram: true,
      appLink: '',
      wxUserId: null,
      idCard: null,
      ifShow: false,
      logShow: '',
    }
  },
  created () {
    const thisObj = this
    // this.wxUserIdData = this.$route.query.wxUserIdData
    // this.idCard = this.$route.query.idCard
    // this.wxUserIdData = window.__APP_CONFIG__?.wxUserIdData;
    // this.idCard = window.__APP_CONFIG__?.idCard;
    // console.log('test window.Android')
    // console.log(window.Android)
    // console.log(Android)
    // this.wxUserId = window.Android.getWxUserId();
    // this.idCard = window.Android.getIdcard();
    thisObj.onLoad();
    thisObj.getParams();
  },
  methods: {
    // getParams: function(){
    //   var wxUserId = Android.getWxUserId();
    //   var idcard = Android.getIdcard();
    //   window.__APP_CONFIG__={wxUserId: wxUserId, idcard: idcard };
    // },
    onLoad: function () {
      const thisObj = this
      // thisObj.getUserInfo()
    },

    goApp() {
      location.href=this.appLink
    },
    getParams(){
      // var wxUserId = Android.getWxUserId();
      // var idcard = Android.getIdcard();
      // window.__APP_CONFIG__={wxUserId: wxUserId, idcard: idcard };
      // if (window.__APP_CONFIG__) {
      //   this.wxUserId = window.__APP_CONFIG__.wxUserId;
      //   this.idCard = window.__APP_CONFIG__.idCard;
      // } else {
      //   console.warn('APP_CONFIG is not defined in the global scope');
      // }
      if ( window.Android ) {
          console.log('存在')
          this.ifShow = true
          this.logShow = this.logShow + ' 存在'
          this.wxUserIdData = window.Android.getWXUserIdData();
          this.idCard = window.Android.getIdCard();
          this.Android = window.Android
          this.getUserInfo()
        } else {
          console.log('不存在')
          this.logShow = this.logShow + ' 不存在'
        }
    },

    getUserInfo (){
      const thisObj = this
      let item = {}
      // console.log('test')
      // console.log(thisObj.wxUserIdData)
      // console.log(thisObj.idCard)
      // item.wxUserId = thisObj.wxUserId
      item.wxUserIdData = thisObj.wxUserIdData
      item.idCard = thisObj.idCard
      userInfo(item).then(res => {
        const data = res.data
        thisObj.userInfo = data
        console.log(thisObj.userInfo)
        if(data == '用户暂未绑定身份证或身份证错误' || data == '用户认证失败'){
          // thisObj.$Message.info('用户暂未绑定身份证或身份证错误');
          // alert('用户暂未绑定身份证或身份证错误')
          console.log('用户暂未绑定身份证或身份证错误')
        }else{
          window.localStorage.setItem('wxUserIdData', thisObj.wxUserIdData)
          window.localStorage.setItem('idCard', thisObj.idCard)
          var timer = setTimeout(function () {
            thisObj.appLink = 'https://dbadminui.dbzdjk.com/externalDynamicDataPage'
            location.href = 'https://dbadminui.dbzdjk.com/externalDynamicDataPage'
            // thisObj.appLink = 'http://localhost:6622/externalDynamicDataPage'
            // location.href = 'http://localhost:6622/externalDynamicDataPage'
          }, 500);
        }
      })
    },

    // 页面跳转
    pageJump (uri, item) {
      const thisObj = this
      let queryData = {
      }
      thisObj.$router.push({
        path: uri,
        query: queryData
      })
    },
    // 页面跳转
    pageJumpCate (uri) {
      const thisObj = this
      let queryData = {
      }
      thisObj.$router.push({
        path: uri,
        query: queryData
      })
    },
  }
}
</script>

<style>
.btn {
    padding: 12px;
    width: 200px;
    height: 50px;
}
.wximg {
  position: fixed;
  width: 30%;
  left: 35%;
  top: 30%;
}
.gotext {
  position: fixed;
  bottom: 20%;
  left: 30%;
  width: 40%;
  height: 80px;
  background: rgb(40, 196, 69);
  color: #ffffff;
  text-align: center;
  font-size: 35px;
  padding: 15px;
  border-radius: 15px;
}
</style>
